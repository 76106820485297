.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-image: url("../../assets/images/Image1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .app-container-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 4rem 8rem;
    padding: 40px 20px;
    width: 600px;
    background-image: url("../../assets/images/top-right.png");
    background-repeat: no-repeat;
    background-color: var(--color-white);
    background-position: top left;
    background-size: 40% auto;
  }

  .app__content {
    width: 400px;
  }

  .app__logo {
    justify-content: center;
    margin-top: 40px;
  }

  .app__logo-img {
    /*margin-left: 3rem;*/
    cursor: pointer;
  }

  .app__or {
    color: var(--color-black);
    font-family: var(--font-base);
    font-weight: bold;
    font-size: 0.8rem;
    margin: 5px;
    text-align: center;
  }

  .app__acct {
    color: var(--color-black);
    font-family: var(--font-base);
    font-size: 18px;
    text-align: center;
    margin-top: 16px;
    font-size: 0.8rem;
    margin-bottom: 2rem;
  }

  .app__acct-alt {
    text-align: left;
  }

  .password {
    color: var(--color-black);
    font-family: var(--font-base);
    font-size: 15px;
    text-align: right;
    font-size: 0.8rem;
    margin-top: 8px;
    margin-bottom: 2rem;
  }

  .app__login-header {
    font-family: var(--font-base);
    color: var(--color-black);
    text-align: center;
    margin-bottom: 2rem;
    font-size: 22px;
  }

  .without-padding label {
    transform: translate(12px, 12px) scale(1);
  }

  .without-padding input {
    padding: 12px;
  }
}

@media screen and (max-width: 768px) {
  .login {
    padding: 0 25px;
    .container {
      height: 100vh;
    }

    .app-container-content {
      width: 100%;
      max-width: 100%;
      min-height: 100vh;
      margin: 2rem;
    }

    .app__content {
      padding: 0 1rem 0 1rem;
    }

    .app__acct {
      font-size: 15px;
    }

    .app__logo-img {
      cursor: pointer;
    }

    .app__login-header {
      text-align: center;
      //margin-bottom: 1rem;
    }

    .app__or {
      text-align: center;
    }
  }
}

@media screen and (max-width: 576px) {
  .login {
    padding: 0 10px;
    .app-container-content {
      background-size: 50% auto;
      padding: 10px 0;
    }

    .app__content {
      width: 100%;
    }
    .login__social--btn {
      font-size: 14px;
    }

    .app__acct {
      margin: 0.5rem 0 1rem 0;
    }
  }
}
