.app__lifestyleinfo-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 600px;
  margin-top: 0;
  margin-left: 0;
  background-image: url("../../assets/images/top-right.png");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 240px 210px;
}

.app__lifestyleinfo-header {
  margin: 5rem 0;
}

.app__wrapper-header-form {
  display: flex;
  flex-direction: column;
}

.app__lifestyleinfo-paragraph {
  color: var(--color-primary);
  font-family: var(--font-base);
  margin-bottom: 4rem;
  padding-left: 5rem;
  font-size: 20px;
  font-weight: 400;
}

.app__lifestyleinfo-acctInfo {
  color: var(--color-black);
  font-family: var(--font-base);
  font-size: 24px;
  font-weight: 600;
}

.app__lifestyleinfo-updatebtn {
  font-family: var(--font-base);
  color: var(--color-white);
  background-color: var(--color-primary);
  border: none;
  margin: 2rem auto 2rem auto;
  cursor: pointer;
  font-size: 21px;
  height: 60px;
  width: 500px;
}

@media screen and (max-width: 650px) {
  .app__lifestyleinfo-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 600px;
    margin-top: 0;
    margin-left: 0;
    background-image: url("../../assets/images/top-right.png");
    background-repeat: no-repeat;
    background-position: top left;
    background-size: 240px 210px;
  }

  .app__lifestyleinfo-header {
    margin: 5rem 0;
  }

  .app__wrapper-header-form {
    display: flex;
    flex-direction: column;
  }

  .app__lifestyleinfo-paragraph {
    color: var(--color-primary);
    font-family: var(--font-base);
    margin-bottom: 4rem;
    padding-left: 5rem;
    font-size: 20px;
    font-weight: 400;
  }

  .app__lifestyleinfo-acctInfo {
    color: var(--color-black);
    font-family: var(--font-base);
    font-size: 24px;
    font-weight: 600;
  }

  .app__lifestyleinfo-updatebtn {
    font-family: var(--font-base);
    color: var(--color-white);
    background-color: var(--color-primary);
    border: none;
    margin: 2rem auto 2rem auto;
    cursor: pointer;
    font-size: 21px;
    height: 60px;
    width: 500px;
  }

  @media screen and (max-width: 650px) {
    .app__lifestyleinfo-updatebtn {
      margin-bottom: 2rem;
      font-size: 18px;
      height: 50px;
      width: 310px;
    }
    .app__input {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  @media screen and (max-width: 850px) {
    .app__wrapper-header-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .app__lifestyleinfo-updatebtn {
    margin-bottom: 2rem;
    font-size: 18px;
    height: 50px;
    width: 310px;
  }
  .app__input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 850px) {
  .app__wrapper-header-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
