/* .app__footer-wrapper {
    width: 100%;
    height: 500px;
    color: var(--color-white);
    background-color: var(--color-footer);
}

@media screen and (max-width: 650px) {
    .app__footer-wrapper {
        width: 100%;
        max-height: 350px;
        margin-top: 4rem;
    }
}
*/
.app__footer-bg {
  /*background-color: var(--color-footer);*/
  /*background-color: rgba(96, 160, 236, 1);*/
  background: url("../../assets/images/footer-bg.jpg") no-repeat;
  background-size: cover;
}
.app__footer {
  width: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background: rgba(31, 93, 188, 1);
  padding: 0 10px 40px;
  /*clip-path: polygon(50% 9%, 100% 30%, 100% 100%, 0% 100%, 0% 30%, 100% 31%, 100% 30%, 0% 29%);*/
  clip-path: polygon(50% 5%, 0% 25%, 0% 100%, 100% 100%, 100% 25%);
}

.app__footer-links {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  /*align-items: flex-start;*/
  margin-top: 7.5rem;
  padding: 0 2rem;
}
.app__footer a {
  color: #fff;
  font-weight: 400;
  line-height: 1.75;
}
.app__footer-links_contact,
.app__footer-links_logo,
.app__footer-links_work {
  flex: 1;
  margin: 1rem;
  text-align: left;
}

.app__footer--item {
}

.app__footer--logocol {
  text-align: center;
  width: 250px;
}
.app__footer-links_logo {
  text-align: center;
  width: 210px;
  margin-bottom: 0.75rem;
}

.app__footer--logocol p {
  text-align: left;
}

.app__footer-links_icons {
  margin-top: 0.5rem;
}

.app__footer-links_icons svg {
  color: var(--color-white);
  margin: 0.5rem;
  font-size: 24px;
  cursor: pointer;
}

.app__footer-links_icons svg:hover {
  color: var(--color-golden);
}

.app__footer-headtext {
  font-family: var(--font-base);
  color: var(--color-white);
  font-weight: 800;
  letter-spacing: 0em;
  text-transform: capitalize;
  font-size: 24px;
  line-height: 41.6px;
  margin-bottom: 1rem;
  text-align: center;
}

.app__footer--quicklinks {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 320px;
}

.footer__copyright {
  margin-top: 3rem;
  opacity: 0.75;
}

.p__opensans {
  color: var(--color-white);
  font-family: var(--font-base);
  font-weight: 400;
  font-size: 16px;
  padding: 3px 0;
  line-height: 22px;
}
.app__footer-group {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  flex-wrap: wrap;
}

.app__footer-links_work,
app__footer-links_contact {
  min-width: 250px;
}

@media screen and (max-width: 768px) {
  .app__footer {
    padding: 0 10px 2rem;
  }
}

@media screen and (max-width: 576px) {
  .app__footer-links_logo img:first-child {
    width: 80%;
  }
}
