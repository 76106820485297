.app__navbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /*height: 104px;*/
  position: relative;
  padding: 24px 3rem 19px 3rem;
}

.app__logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.app__logo-img {
  width: auto;
  height: 46px;
}

.app__navbar-links {
  flex: 1;
  display: flex;
  list-style: none;
  justify-content: flex-end;
  align-items: center;
}

.app__navbar-links li {
  margin: 0 1.5rem;
  cursor: pointer;
  color: var(--color-black);
  font-weight: 400;
  font-family: var(--font-base);
}
.app__navbar-links li:last-child {
  margin-right: 0;
}

.app__navbar-links li:hover,
.app__navbar-links a:hover {
  color: var(--color-secondary-alt);
  font-weight: 500;
}

.app__navbar--menu {
  display: none;
}

@media screen and (max-width: 768px) {
  .app__navbar {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 90px;
    padding: 24px 3rem 19px 3rem;
  }

  .app__logo {
    cursor: pointer;
  }
  .app__navbar-links {
    display: block;
    cursor: pointer;
    position: absolute;
    width: 100%;
    top: 80px;
    right: 0;
    background-color: var(--color-white);
    animation: moveInTop 0.75s ease-out;
    padding-bottom: 30px;
    z-index: 10;
  }

  .app__navbar-links-show {
    display: block;
  }
  .app__navbar-links-hide {
    display: none;
  }

  .app__navbar-links li {
    margin: 20px 1rem;
    display: block;
    text-align: center;
  }

  .app__navbar-links li:hover {
    color: var(--color-secondary);
  }
  app__navbar--links-show {
    display: block;
  }
  .app__navbar--menu {
    display: block;
  }
}
@media screen and (max-width: 576px) {
  .app__navbar {
    padding: 24px 1.5rem 19px 1.5rem;
  }
}
