.app__divider {
  width: 100%;
  height: 277px;
  background-color: var(--color-grey);
}
.app__divider-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  width: 90%;
  margin: 0 auto;
}
.app__divider-text {
  font-family: var(--font-base);
  color: var(--color-black);
  font-weight: 600;
  font-size: 40px;
}
@media screen and (max-width: 650px) {
  .app__divider-text {
    font-size: 25.5px;
  }
  .app__divider {
    height: 208px;
  }
}

@media screen and (max-width: 568px) {
  .app__divider-text {
    font-size: 20px;
  }
  .app__divider {
    height: 156px;
  }
}
