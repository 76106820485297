.app__testimonials-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10rem 25px 6rem;
  background: url("../../../assets/images/blue-dots.png") no-repeat bottom left,
    url("../../../assets/images/blue-dots.png") no-repeat top right;
}

.app__testimonials-header {
  margin-bottom: 4rem;
  text-align: center;
}

.app__testimonials-content {
  max-width: 400px;
  width: 100%;
}

.app__testimonials-avatar {
  text-align: center;
  margin: 1rem auto;
}

.app__testimonials-paragraph {
  margin-bottom: 2rem;
}

.app__testimonials-name {
  font-family: var(--font-base);
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

@media screen and (max-width: 992px) {
  .app__testimonials-header {
    font-size: 44px;
    /*font-weight: 500;*/
  }
}

@media screen and (max-width: 768px) {
  .app__testimonials-wrapper {
    background-size: 100px auto;
  }
  .app__testimonials-paragraph {
  }
}
@media screen and (max-width: 570px) {
  .app__testimonials-wrapper {
    background-size: 75px auto;
  }
}
