.app__roomupload-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 600px;
  margin-top: 0;
  margin-left: 0;
  background-image: url("../../assets/images/top-right.png");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 240px 210px;
}

.app__roomupload-header {
  margin: 5rem 0;
}

.app__wrapper-header-form {
  display: flex;
  flex-direction: column;
}

.app__roomupload-paragraph {
  color: var(--color-primary);
  font-family: var(--font-base);
  margin-bottom: 4rem;
  margin-left: 20rem;
  font-size: 20px;
  font-weight: 400;
}

.app__roomupload-roomPicture {
  color: var(--color-black);
  font-family: var(--font-base);
  font-size: 24px;
  font-weight: 600;
  margin-left: 16rem;
  margin-bottom: 6rem;
}

.app__roomupload-max {
  font-family: var(--font-base);
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  color: #4f4f4f;
}

.app__roomupload-DragAndDrop {
  font-family: var(--font-base);
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  color: #4f4f4f;
  margin-top: 15rem;
  margin-left: 25rem;
}

.app__inputInfoAboutPic {
  border: none;
  height: 40px;
  border-bottom: 3px solid rgba(215, 229, 245, 0.59);
  margin-bottom: 100px;
}

.app__inputInfoAboutPic::placeholder {
  font-family: var(--font-base);
  font-weight: 600;
  font-size: 18px;
  color: #4f4f4f;
  padding-left: 10px;
  padding-bottom: 3rem;
}

.app__roomupload {
  width: 1270px;
  height: 500px;
  border: 6px solid rgba(215, 229, 245, 0.59);
  margin-bottom: 89px;
  margin-left: auto;
  margin-right: auto;
}

.app__roomupload-btn {
  font-family: var(--font-base);
  color: var(--color-white);
  background-color: var(--color-primary);
  border: none;
  margin: 2rem auto 89px auto;
  cursor: pointer;
  font-size: 21px;
  width: 950px;
  height: 85px;
}

@media screen and (max-width: 450px) {
  .app__roomupload-roomPicture {
    font-size: 20px;
    font-weight: 400;
    margin-left: 9rem;
    margin-bottom: 6rem;
  }

  .app__roomupload-paragraph {
    margin-bottom: 4rem;
    margin-left: 10rem;
    font-size: 20px;
    font-weight: 400;
  }

  .app__roomupload-header {
    margin: 5rem 0;
  }

  .app__wrapper-header-form {
    display: flex;
    flex-direction: column;
  }

  .app__roomupload-paragraph {
    margin-top: 5rem;
    margin-bottom: 4rem;
    padding-left: 2rem;
    font-size: 20px;
    font-weight: 400;
  }

  .app__roomupload-acctInfo {
    color: var(--color-black);
    font-family: var(--font-base);
    font-size: 24px;
    font-weight: 600;
  }

  .app__roomupload {
    width: 300px;
    height: 360px;
  }

  .app__roomupload-max {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    padding-left: 10px;
    margin: 0 10px 0 10px;
  }

  .app__roomupload-DragAndDrop {
    font-weight: 200;
    font-size: 18px;
    line-height: 28px;
    color: #4f4f4f;
    margin-top: 10rem;
    margin-left: 3.8rem;
  }

  .app__roomupload-btn {
    margin: 2rem auto 89px auto;
    font-size: 21px;
    width: 380px;
    height: 60px;
  }

  .app__inputInfoAboutPic {
    width: 350px;
  }

  .app__inputInfoAboutPic::placeholder {
    font-weight: 400;
    font-size: 18px;
    color: #4f4f4f;
    padding-left: 10px;
    padding-bottom: 3rem;
  }
}

@media screen and (max-width: 650px) {
}

@media screen and (min-width: 850px) {
  .app__roomupload {
    width: 600px;
  }

  .app__roomupload-DragAndDrop {
    font-weight: 200;
    font-size: 18px;
    line-height: 28px;
    color: #4f4f4f;
    margin-left: 8rem;
  }

  .app__roomupload-btn {
    width: 650px;
    height: 85px;
  }

  .app__inputInfoAboutPic {
    width: 450px;
  }

  .app__roomupload-max {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    padding-left: 10px;
    margin: 0 10px 0 10px;
  }

  .app__roomupload-roomPicture {
    margin-left: 10rem;
    margin-bottom: 6rem;
  }

  .app__roomupload-paragraph {
    margin-left: 13rem;
  }
}
