.app__header {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 80px);
  background: rgba(0, 0, 0, 0.65);
  position: relative;
}

.app__header--bg {
  background-image: url("../../assets/images/Image1.png");
  background-size: 100% calc(100vh - 80px);
}

.app__header--content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}

.app__header-text {
  margin: 0 auto;
  width: 80%;
}

.app__appartment-btn {
  margin: 50px auto 0;
  display: flex;
  justify-content: space-evenly;
  max-width: 1450px;
}

.app__appartment-app {
  width: 380px;
  height: 97px;
  color: var(--color-white);
  background-color: var(--color-primary);
  font-family: var(--font-base);
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  border: none;
  outline: none;
  cursor: pointer;
  display: inline-block;
}

.app__header-title {
  font-size: 64px;
  font-weight: 800;
  line-height: 1.25;
  color: var(--color-white);
  font-family: var(--font-base);
  margin-bottom: 38px;
}

.app__header-paragraph {
  font-family: var(--font-base);
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  line-height: 1.25;
  color: var(--color-white);
}

@media screen and (max-width: 992px) {
  .app__header-title {
    font-size: 44px;
    /*font-weight: 500; */
  }

  .app__header-paragraph {
    /*font-weight: 400;*/
    font-size: 14px;
    text-align: center;
    line-height: 20px;
  }

  .app__appartment-app {
    width: 250px;
    height: 60px;
    font-size: 18px;
  }
}

@media screen and (max-width: 768px) {
  .app__header {
    height: 600px;
  }

  .app__header-title {
    font-size: 34px;
  }

  .app__header-paragraph {
    font-size: 14px;
    text-align: center;
  }

  .app__header-text {
  }

  .app__appartment-app {
    width: 220px;
    height: 40px;
    font-size: 12px;
  }
}
@media screen and (max-width: 576px) {
  .app__header-text {
    width: 90%;
  }
  .app__appartment-btn {
    display: block;
    margin: 50px auto 0;
    text-align: center;
  }
  .app__appartment-app {
    display: block;
    margin: 0 auto 20px;
    &:not(:last-child) {
    }
  }
}
