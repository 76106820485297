.app__whyus-container {
  display: block;
  width: 100%;
  margin: 150px 0 4rem 0;
  padding: 46px 20px;
  text-align: center;
}

.app__whyus-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  max-width: 1500px;
  margin: 0 auto;
}

.app__icon {
  text-align: center;
  height: 90px !important;
  width: 90px !important;
}

.app__whyus-easy {
  text-align: center;
  font-size: 28px;
  font-weight: 800;
  margin: 0 1.5rem;
  color: var(--color-black);
  font-family: var(--font-base);
}
.app__whyus-content {
  max-width: 400px;
  margin: 3rem auto;
}
.app__whyus-header {
  font-size: 64px;
  font-weight: 800;
}

@media screen and (max-width: 1150px) {
  .app__whyus-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .app__whyus-header {
    font-size: 64px;
    font-weight: 800;
  }

  .app__whyus-paragraph {
    padding-top: 1rem;
  }

  .app__whyus-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  .app__whyus-container {
    margin-top: 75px;
  }
  .app__whyus-header {
    font-size: 48px;
  }
  .app__whyus-easy {
    font-size: 24px;
    font-weight: 700;
    margin: 0 1.125rem;
  }
  .app__whyus-header {
    font-size: 27px;
    font-weight: 800;
  }
  .app__icon {
    height: 67.5px !important;
    width: 67.5px !important;
  }
}
@media screen and (max-width: 576px) {
  .app__whyus-container {
    margin-top: 36px;
  }
  .app__icon {
    text-align: center;
    height: 50px !important;
    width: 50px !important;
  }
}
