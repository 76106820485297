.findroommate {
  .app__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background: url("../../../assets/images/top-right.png") no-repeat top left;
    background-size: 25% auto;
    padding: 0 10px;
  }

  .app__wrapper-text {
    margin-top: 7.5rem;
    text-align: center;
  }

  .app__wrapper-header {
    color: var(--color-black);
    font-family: var(--font-base);
    font-weight: 700;
    font-size: 64px;
    line-height: 1.25;
  }

  .app__wrapper-paragraph {
    margin-top: 38px;
    color: var(--color-black);
    font-family: var(--font-base);
    text-align: center;
    font-size: 24px;
    margin-bottom: 61px;
  }

  /* Avatar component Style*/
  .app__avatar-wrapper {
    width: 100%;
    max-width: 290px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
  }

  .app__wrapper-img {
    width: 211px;
    height: 211px;
    border-radius: 50%;
    box-shadow: 1px 0px 6px 1px rgba(96, 160, 236, 0.75);
    -webkit-box-shadow: 1px 0px 6px 1px rgba(96, 160, 236, 0.75);
    -moz-box-shadow: 1px 0px 6px 1px rgba(96, 160, 236, 0.75);
  }

  .app__name {
    color: var(--color-black);
    font-family: var(--font-base);
    font-weight: 400;
    font-size: 24px;
    margin-top: 1rem;
  }

  .app__location {
    color: var(--color-black);
    font-family: var(--font-base);
    font-weight: 400;
    font-size: 19px;
    margin-top: 1rem;
  }

  .app__avatar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  /* Avatar component Style ends here*/

  .app__getstarted-bg {
    background: url("../../../assets/images/blue-dots.png") no-repeat bottom
        right,
      url("../../../assets/images/blue-dots.png") no-repeat top left;
    background-size: 100px auto;
    padding: 50px 50px;
    width: 77%;
    margin: 100px auto 0;
  }

  .app__getstarted {
    border: 4px solid #060606;
    padding: 50px 0;
    margin: 0 auto;
  }

  .app__getstarted-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 10px;
  }

  .app__getstarted-btn {
    width: 287px;
    height: 89px;
    font-size: 24px;
    margin: 0 0 36px 0;
    cursor: pointer;
    color: var(--color-white);
    font-family: var(--font-base);
    background-color: var(--color-primary);
  }

  .app__getstarted-text {
    color: var(--color-black);
    font-family: var(--font-base);
    font-weight: 500;
    font-size: 24px;
  }
}
@media screen and (max-width: 992px) {
  .findroommate {
    .app__getstarted-bg {
      width: 88%;
    }
    .app__wrapper {
      background-size: 35% auto;
    }

    .app__wrapper-header {
      font-size: 50px;
    }

    .app__wrapper-paragraph {
      margin-top: 38px;
      font-size: 20px;
    }
  }
}

@media screen and (max-width: 768px) {
  .findroommate {
    .app__getstarted-bg {
      background-size: 75px auto;
      padding: 25px 25px;
    }
    .app__wrapper-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }

    .app__wrapper-header {
      font-size: 48px;
    }

    .app__avatar {
      display: flex;
      //flex-direction: column;
      justify-content: center;
    }

    .app__getstarted-text {
      font-weight: 400;
      //font-size: 20px;
    }

    .app__getstarted-btn {
      width: 207px;
      height: 70px;
      font-size: 20px;
    }
  }
}

@media screen and (max-width: 576px) {
  .findroommate {
    .app__getstarted-bg {
      background-size: 50px auto;
      padding: 25px 25px;
      width: 100%;
    }
    .app__wrapper-header {
      font-size: 36px;
    }
    .app__wrapper-paragraph {
      margin-top: 26px;
    }
    .app__getstarted-text {
      font-size: 18px;
    }
    .app__wrapper-img {
      width: 150px;
      height: 150px;
    }
  }
}
