@import url("https://fonts.googleapis.com/css2?family=Cormorant+Upright:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;1,300;1,400;1,900&display=swap");

:root {
  --font-base: "Lato", sans-serif;
  --font-alt: "Cormorant Upright";

  --color-secondary: rgba(243, 47, 200, 0.33);
  --color-secondary-alt: rgb(243, 47, 200);
  --color-primary: #60a0ec;
  --color-footer: #1f5dbc;
  --color-grey: rgba(215, 229, 245, 0.59);
  --color-white: #ffffff;
  --color-black: #060606;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  font-size: 16px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  line-height: 1.5;
  /*max-width: 1365px;*/
  margin: 0 auto;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

a {
  text-decoration: none;
}
a:hover {
  font-size: 1.025em;
  transform: translateY(-0.05rem);
}
.heading {
  color: var(--color-black);
  font-family: var(--font-base);
  font-weight: 700;
  font-size: 64px;
}

.paragraph {
  color: var(--color-black);
  font-family: var(--font-base);
  font-size: 24px;
  font-weight: 400;
  line-height: 1.5;
  text-align: justify;
}
button {
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 0.25rem;
  padding: 0.75rem 1.5rem;
  display: inline-block;
  border: none;
  transition: all 0.2s;
  position: relative;
  font-weight: bolder;
}
button:hover {
  transform: translateY(-0.05rem);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}

button:active {
  transform: translateY(-0.1rem);
  border: var(--color-black);
}

@media screen and (max-width: 768px) {
  .heading {
    font-size: 48px;
  }
  .paragraph {
    font-size: 20px;
  }
}

@media screen and (max-width: 576px) {
  .heading {
    font-size: 36px;
  }
  .paragraph {
    font-size: 16px;
  }
}

/* Bootstrap break points for use in all part of the website */

@media screen and (max-width: 1200px) {
  /**/
}
@media screen and (max-width: 992px) {
}

@media screen and (max-width: 768px) {
  /*  MD: For tablets, phones in potriate and smaller screens */
}

@media screen and (max-width: 576px) {
  /*  SM: For Mobile devices and smaller */
}

@keyframes moveInTop {
  0% {
    opacity: 0;
    transform: translateY(-10rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }
  80% {
    opacity: 0.8;
    transform: translateX(2rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem);
  }
  80% {
    opacity: 0.8;
    transform: translateX(-2rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
