.app__header-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 900px;
  background-image: url("../../assets/images/Image1.png");
  background-size: 100% 900px;
  background-repeat: no-repeat;
}

.app__header-text-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.app__header-title-component {
  font-size: 64px;
  font-weight: 800;
  line-height: 59px;
  color: var(--color-white);
  font-family: var(--font-base);
  margin-bottom: 38px;
}

.app__header-paragraph-component {
  font-family: var(--font-base);
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  line-height: 40px;
  color: var(--color-white);
}

@media screen and (max-width: 650px) {
  .app__header-component {
    height: 750px;
  }
  .app__header-title-component {
    font-size: 34px;
    font-weight: 400;
    line-height: 29px;
  }

  .app__header-paragraph-component {
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    line-height: 17px;
    padding: 0 1rem;
  }
}
