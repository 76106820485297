.find-cross-site {
  .app__wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 6rem;
    padding: 0 25px;
  }

  .app__wrap-head {
    padding: 46px 0 0;
    font-weight: 800;
    text-align: center;
    margin: 150px 0 3.5rem;
  }

  .app__city {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  .app__city-item {
    position: relative;
    display: inline-block;
    height: 400px;
    border: 1px solid red;
    max-width: 300px;
    width: 100%;
    margin: 15px;
    overflow: hidden;
  }

  .app__city-btn {
    position: absolute;
    padding: 8px 16px;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(215, 229, 245, 0.59);
    color: white;
    display: flex;
    align-content: center;
  }

  .app__city-btn-icon {
    vertical-align: center;
  }

  .app__city-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 3rem;
  }

  .app__city-other {
    text-align: center;
  }

  .app__city-img {
    width: 100%;
    height: 100%;
  }

  .app__city-wrapper-content {
    margin-top: 2rem;
    display: flex;
    height: 400px;
    overflow-y: scroll;
    justify-content: center;
  }

  .app__city-wrapper-content-city {
    display: flex;
    flex-direction: column;
  }

  .app__city-wrapper-content-para {
    padding: 0 15px;
    //&:not(:last-child){
    margin-top: 2rem;
    //}
  }
}
@media screen and (max-width: 769px) {
  .find-cross-site {
    .app__wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
    .app__city-wrapper-content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }

    .app__city-wrapper-content-para {
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 1150px) {
  .find-cross-site {
    .app__wrapper {
      padding: 0 10px;
    }
    .app__city-wrapper-content-city {
    }
    .app__city-wrapper-content-para {
      font-size: 18px;
    }
  }
}
